import React from "react";
import { NavLink } from "react-router-dom";
import BannerMobile from "../../assets/images/appImages/banner-mobile-noel.webp";
function BannerHomepage() {
  return (
    <section className="section-banner-homepage">
      <div className="d-flex align-items-end h-100">
        <div className="content-col-left-banner-accueil">
          {/* <h2 className="title-home-page">
              ADUUNA
            </h2>
            <h2 className="title-home-page second-color">
              BUL <br /> COMPRENDRE
            </h2>
            <div className="content-text-home-page-banner mt-2 mb-5">
              <p className="text-home-page-banner">
                Magasin de Musique • CD • Vinyls • Cassettes et accessoires
              </p>
            </div> */}
          <div className="content-img-bg-banner-abc-mobile no-view-desktop">
            <img
              src={BannerMobile}
              alt="Banner"
              className="img-bg-banner-abc-mobile"
            />
          </div>
          <div className="container-btn-banner-accueil pt-3">
            <NavLink className="btn btn-title-home-page" to="/produits">
              Voir nos produits
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BannerHomepage;
