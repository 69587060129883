import photo1 from "../../../assets/images/appImages/member-1.png";
import photo2 from "../../../assets/images/appImages/member-2.png";
import photo3 from "../../../assets/images/appImages/member-3.png";
import photo4 from "../../../assets/images/appImages/member-4.png";
import photo5 from "../../../assets/images/appImages/member-5.png";

export const TeamData = [
  {
    id: 1,
    photo: photo1,
    name: "Ndeye Absa GNINGUE",
    role: "Fondatrice",
  },
  {
    id: 2,
    photo: photo2,
    name: "Raky PENE",
    role: "Gérante",
  },
  {
    id: 3,
    photo: photo3,
    name: "Mady KONATE",
    role: "Infographe",
  },
  {
    id: 4,
    photo: photo4,
    name: "Eugène SENE",
    role: "Direction artistique & Community Management",
  },
  {
    id: 5,
    photo: photo5,
    name: "Khoudia DIOP",
    role: "Commercial",
  },
];
