import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import illustration from "../../../assets/images/appImages/illustration.png";
import { TeamData } from "./data";
import { Link } from "react-router-dom";

const CustomBeautyTitle = ({ title }: { title: string }) => {
  return <h2 className="custom-esb-title">{title}</h2>;
};

const TeamCard = ({
  photo,
  name,
  role,
  twitter,
  facebook,
  linkedin,
}: {
  photo?: string;
  name: string;
  role: string;
  twitter?: string;
  facebook?: string;
  linkedin?: string;
}) => {
  return (
    <div className="team-card">
      <span className="photo-wrapper">
        <img loading="lazy" src={photo} alt="photo" />
      </span>
      <span className="name">{name}</span>
      <span className="role">{role}</span>
    </div>
  );
};

function WhoWeAre() {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content who-we-are-page pb-5 pb-5">
        <h1 className="custom-page-title">Qui sommes-nous ?</h1>
        <div className="who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="illustration-container">
                  <img loading="lazy" src={illustration} alt="image" />
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <CustomBeautyTitle title="Qui sommes nous?" />
                <h3 className="custom-title">ADUUNA BUL COMPRENDRE</h3>
                <div className="custom-content">
                  ABC est un concept store situé dans le quartier traditionnel de Ouakam à Dakar. L'entreprise propose une boutique,
                  un studio photo, une salle privée, un coin repas, et une aire de jeux pour enfants. Sa fondatrice, Ndeye Absa Gningue,
                  passionnée par les cultures africaines, a toujours voulu promouvoir le concept "LOVE AFRICA. WEAR AFRICA" comme un
                  véritable état d'esprit, bien au-delà du simple port vestimentaire.
                </div>
              </div>
              <div className="col-lg-12 mb-4">
                <div className="custom-content">
                  ABC présente des créations qui reflètent un univers riche et métissé, combinant les produits de nos terroirs avec les
                  tendances inspirées du Sénégal et de l'Afrique. Depuis 2023, la marque a élargi son offre en développant un volet
                  HOME DECO, dédié à la personnalisation des espaces personnels et professionnels, ainsi qu'une expertise en Marketing
                  et Communication (Personal Branding & Corporate Communication).
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-team">
          <div className="container">
            <CustomBeautyTitle title="Team" />
            <h3 className="custom-title">Notre équipe</h3>
            <div className="row pt-4">
              {!!TeamData &&
                TeamData.map((member, key) => {
                  return (
                    <div className="col-md-3 col-lg-4 mb-4 card-wrapper">
                      <TeamCard
                        photo={member.photo}
                        name={member.name}
                        role={member.role}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="plateform-founders">
          <div className="container">
            <CustomBeautyTitle title="Qui sommes nous?" />
            <h3 className="custom-title">Mot de la fondatrice</h3>
            <div className="row pt-4">
              <div className="col-md-12">
                <div className="founder-card">
                  <p className="description">
                    Je suis <strong>Ndeye Absa Gningue</strong>, fondatrice de la marque ABC-Aduuna Bul Comprendre (Love Africa. Wear Africa).
                    Lorsque j'ai lancé ABC, mon objectif était de créer bien plus qu'une simple marque de vêtements. Je voulais partager
                    un état d'esprit, une célébration de notre riche héritage africain, une manière de porter fièrement nos cultures à travers
                    le monde mais aussi un rappel que tout dans la vie n’est que recommencement et apprentissage
                    (sans que l’on ne comprenne forcément pourquoi les choses se font comme elles se font), mais également les 3 premières
                    lettres de toutes les formes d’alphabet. <br />
                    ABC est né d'une passion profonde pour l'Afrique, pour ses traditions, ses couleurs,
                    et ses talents. À travers nos créations, nous cherchons à valoriser le MADE IN AFRICA, en donnant une plateforme aux artisans
                    locaux et en soutenant ceux qui, comme les détenus du programme Prison Break (PBP), méritent une seconde chance. Notre mission
                    est de montrer au monde la beauté et la richesse de notre continent, non seulement à travers la mode, mais aussi à travers
                    l'engagement social et la solidarité. Je suis immensément fière de ce que nous avons accompli jusqu'à présent, et je suis
                    convaincue que ce n'est que le début d'une aventure extraordinaire. <br />
                    En tant que fondatrice, je souhaite que chaque personne qui porte ABC se sente connectée à l'Afrique, à sa diversité et à sa
                    résilience. Ensemble, nous pouvons continuer à bâtir des ponts entre les cultures, à inspirer, et à faire briller l'Afrique sur
                    la scène mondiale. Merci à toutes celles et ceux qui ont soutenu ABC depuis le début, et à ceux qui nous rejoignent dans cette
                    belle aventure, avec passion et détermination.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
}

export default WhoWeAre;
